var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bus-travel-details-page" },
    [
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "details-model",
              attrs: {
                title: "查看",
                width: "1000px",
                visible: _vm.dialogVisible,
                "before-close": _vm.beforeClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-row",
                _vm._l(_vm.detailsList, function (info, i) {
                  return _c("el-col", { key: i, attrs: { span: 8 } }, [
                    _c("span", { staticClass: "row-label" }, [
                      _vm._v(_vm._s(info.label)),
                    ]),
                    info.value === "price" || info.value === "realityPrice"
                      ? _c("span", { staticClass: "row-value" }, [
                          _vm._v(
                            _vm._s(_vm.detailsObj[info.value] || 0) + "元"
                          ),
                        ])
                      : info.value === "payType"
                      ? _c("span", { staticClass: "row-value" }, [
                          _vm._v(
                            _vm._s(
                              _vm.detailsObj[info.value] == 0
                                ? "微信"
                                : _vm.detailsObj[info.value] == 1
                                ? "支付宝"
                                : _vm.detailsObj[info.value] == 2
                                ? "银联"
                                : _vm.detailsObj[info.value] == 3
                                ? "现金"
                                : ""
                            )
                          ),
                        ])
                      : info.value === "orderStatus"
                      ? _c("span", { staticClass: "row-value" }, [
                          _vm._v(
                            _vm._s(
                              _vm.detailsObj[info.value] == 0
                                ? "待支付"
                                : _vm.detailsObj[info.value] == 1
                                ? "已支付"
                                : _vm.detailsObj[info.value] == 2
                                ? "支付失败"
                                : ""
                            )
                          ),
                        ])
                      : _c("span", { staticClass: "row-value" }, [
                          _vm._v(_vm._s(_vm.detailsObj[info.value])),
                        ]),
                  ])
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }