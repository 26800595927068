<template>
  <div class="bus-travel-details-page">
    <el-dialog
      v-if="dialogVisible"
      title="查看"
      width="1000px"
      :visible.sync="dialogVisible"
      :before-close="beforeClose"
      class="details-model"
    >
      <el-row>
        <el-col v-for="(info, i) in detailsList" :key="i" :span="8">
          <span class="row-label">{{ info.label }}</span>
          <span
            v-if="info.value === 'price' || info.value === 'realityPrice'"
            class="row-value"
            >{{ detailsObj[info.value] || 0 }}元</span
          >
          <span v-else-if="info.value === 'payType'" class="row-value">{{
            detailsObj[info.value] == 0
              ? "微信"
              : detailsObj[info.value] == 1
              ? "支付宝"
              : detailsObj[info.value] == 2
              ? "银联"
              : detailsObj[info.value] == 3
              ? "现金"
              : ""
          }}</span>
          <span v-else-if="info.value === 'orderStatus'" class="row-value">{{
            detailsObj[info.value] == 0
              ? "待支付"
              : detailsObj[info.value] == 1
              ? "已支付"
              : detailsObj[info.value] == 2
              ? "支付失败"
              : ""
          }}</span>
          <span v-else class="row-value">{{ detailsObj[info.value] }}</span>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { selectOneBusOrderAPI } from "@/api/lib/api.js";
export default {
  data() {
    return {
      dialogVisible: false,
      detailsList: [
        { label: "订单号", value: "orderNumber" },
        { label: "渠道", value: "channel" },
        { label: "城市", value: "city" },
        { label: "公交公司", value: "busCompany" },
        { label: "用户手机", value: "phone" },
        { label: "公交电子卡卡号", value: "cardNumber" },
        { label: "设备编号", value: "equipmentNumber" },
        { label: "线路编号", value: "lineNumber" },
        { label: "刷码时间", value: "brushCodeTime" },
        { label: "票价", value: "price" },
        {
          label: "实际票价",
          value: "realityPrice",
        },
        { label: "支付类型", value: "payType" },
        { label: "支付时间", value: "payTime" },
        {
          label: "订单状态",
          value: "orderStatus",
        },
      ],
      detailsObj: {},
    };
  },
  methods: {
    openModel(id) {
      this.dialogVisible = true;
      selectOneBusOrderAPI({ id }).then((res) => {
        if (res.code === "SUCCESS") {
          this.detailsObj = res.data;
        }
      });
    },
    beforeClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.bus-travel-details-page {
  ::v-deep .details-model {
    .el-dialog__header {
      border-bottom: 1px solid #d7d7d7;
      padding: 18px 24px;
      margin: 0 20px 10px 20px;
      .el-dialog__title {
        font-weight: 400;
      }
    }
    .el-dialog__body {
      padding: 0 20px;
      .el-row {
        .el-col {
          margin-bottom: 20px;
          color: #333333;
          display: flex;
          align-items: center;
        }
        .row-label {
          display: inline-block;
          width: 100px;
          text-align: right;
        }
        .row-value {
          display: inline-block;
          width: calc(100% - 160px);
          height: 34px;
          padding: 0 10px;
          line-height: 34px;
          border: 1px solid #d3d3d3;
          border-radius: 4px;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>