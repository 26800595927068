var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "busTravelOrder", staticClass: "bus-travel-order-page" },
    [
      _c("div", { ref: "functionButtons", staticClass: "function-buttons" }),
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                model: _vm.form,
                "label-position": "left",
                "label-width": "85px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单编号：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入订单编号" },
                    model: {
                      value: _vm.form.orderNumber,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "orderNumber",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.orderNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "渠道：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择渠道" },
                      model: {
                        value: _vm.form.channel,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "channel", $$v)
                        },
                        expression: "form.channel",
                      },
                    },
                    _vm._l(_vm.channelList, function (channel) {
                      return _c("el-option", {
                        key: channel.value,
                        attrs: { label: channel.label, value: channel.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "城市：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择城市" },
                      model: {
                        value: _vm.form.city,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "city", $$v)
                        },
                        expression: "form.city",
                      },
                    },
                    _vm._l(_vm.cityList, function (city) {
                      return _c("el-option", {
                        key: city.value,
                        attrs: { label: city.label, value: city.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属公司：" } },
                [
                  _c("companySelect", {
                    attrs: {
                      "company-tree": _vm.companyList,
                      valuename: _vm.form.busCompany,
                      clearable: "",
                    },
                    on: { getValue: _vm.getAddGroupId, getName: _vm.getName },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用户手机：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入用户手机" },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "phone",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "订单状态：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "collapse-tags": "",
                        multiple: "",
                        clearable: "",
                        placeholder: "请选择订单状态",
                      },
                      model: {
                        value: _vm.form.orderStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "orderStatus", $$v)
                        },
                        expression: "form.orderStatus",
                      },
                    },
                    _vm._l(_vm.orderStatusList, function (role) {
                      return _c("el-option", {
                        key: role.value,
                        attrs: { label: role.label, value: role.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "code-time-range",
                  attrs: { label: "刷码时间段：" },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.codeTime,
                      callback: function ($$v) {
                        _vm.codeTime = $$v
                      },
                      expression: "codeTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.searchLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onSearch()
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        loading: _vm.searchLoading,
                        type: "primary",
                        size: "small",
                      },
                      on: { click: _vm.exportFun },
                    },
                    [_vm._v("导出")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Table", {
        attrs: {
          "table-data": _vm.tableData,
          "title-name": _vm.titleName,
          "col-class-type": true,
          "table-height": _vm.tableHeight,
          operation: true,
          "operation-width": "60",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.openDetails(scope.scopeRow)
                      },
                    },
                  },
                  [_vm._v("查看")]
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        { ref: "pagination", staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.form.pageSize,
              "current-page": _vm.form.currentPage,
            },
            on: {
              "size-change": _vm.onSizeChange,
              "current-change": _vm.onCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("busTravelDetails", { ref: "busTravelDetails" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }