<template>
  <!-- 公交出行订单 -->
  <div ref="busTravelOrder" class="bus-travel-order-page">
    <div ref="functionButtons" class="function-buttons"></div>
    <div ref="form" class="form-area">
      <el-form
        :inline="true"
        :model="form"
        label-position="left"
        label-width="85px"
        class="demo-form-inline"
      >
        <el-form-item label="订单编号：">
          <el-input
            v-model.trim="form.orderNumber"
            placeholder="请输入订单编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="渠道：">
          <el-select v-model="form.channel" clearable placeholder="请选择渠道">
            <el-option
              v-for="channel in channelList"
              :key="channel.value"
              :label="channel.label"
              :value="channel.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="城市：">
          <el-select v-model="form.city" clearable placeholder="请选择城市">
            <el-option
              v-for="city in cityList"
              :key="city.value"
              :label="city.label"
              :value="city.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属公司：">
          <companySelect
            :company-tree="companyList"
            :valuename="form.busCompany"
            clearable
            @getValue="getAddGroupId"
            @getName="getName"
          ></companySelect>
        </el-form-item>
        <el-form-item label="用户手机：">
          <el-input
            v-model.trim="form.phone"
            placeholder="请输入用户手机"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单状态：">
          <el-select
            v-model="form.orderStatus"
            collapse-tags
            multiple
            clearable
            placeholder="请选择订单状态"
          >
            <el-option
              v-for="role in orderStatusList"
              :key="role.value"
              :label="role.label"
              :value="role.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="刷码时间段：" class="code-time-range">
          <el-date-picker
            v-model="codeTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="small"
            :loading="searchLoading"
            @click="onSearch()"
            >查询</el-button
          >
          <el-button
            :loading="searchLoading"
            type="primary"
            size="small"
            @click="exportFun"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :col-class-type="true"
      :table-height="tableHeight"
      :operation="true"
      operation-width="60"
    >
      <template slot-scope="scope">
        <el-button type="text" size="small" @click="openDetails(scope.scopeRow)"
          >查看</el-button
        >
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <busTravelDetails ref="busTravelDetails"></busTravelDetails>
  </div>
</template>

<script>
import moment from "moment";
import busTravelDetails from "./busTravelDetails.vue";
import companySelect from "@/components/treeSelect/companySelect.vue";
import {
  selectBusOrderByKeyWordAPI,
  busTravelConfigurationListAPI,
  busTravelConfigurationCompanyTreeAPI,
  getBusOrderExportAPI,
} from "@/api/lib/api.js";
export default {
  components: {
    busTravelDetails,
    companySelect,
  },
  data() {
    return {
      exportParams: {},
      form: {
        orderNumber: null,
        channel: null,
        city: null,
        busCompany: "",
        phone: "",
        orderStatus: null,
        currentPage: 1, //页数
        pageSize: 10, //分页大小
      },
      codeTime: "", //刷码时间
      channelList: [{ value: 10, label: "广运神马" }],
      cityList: [],
      companyList: [],
      orderStatusList: [
        { value: 0, label: "待支付" },
        { value: 1, label: "已支付" },
        { value: 2, label: "支付失败" },
      ],
      titleName: [
        {
          title: "订单编号",
          props: "orderNumber",
          width: 200,
        },
        {
          title: "渠道",
          props: "channel",
          width: 200,
        },
        {
          title: "城市",
          props: "city",
          width: 200,
        },
        {
          title: "公交公司",
          props: "busCompany",
          width: 200,
        },
        {
          title: "用户手机",
          props: "phone",
          width: 200,
        },
        {
          title: "公交电子卡卡号",
          props: "cardNumber",
          width: 200,
        },
        {
          title: "设备编号",
          props: "equipmentNumber",
          width: 200,
        },
        {
          title: "线路编号",
          props: "lineNumber",
          width: 200,
        },
        {
          title: "刷码时间",
          props: "brushCodeTime",
          width: 200,
        },
        {
          title: "票价",
          props: "price",
          width: 200,
          SpecialJudgment: (res) => {
            return res + "元";
          },
        },
        {
          title: "实际票价",
          props: "realityPrice",
          width: 200,
          SpecialJudgment: (res) => {
            return res + "元";
          },
        },
        {
          title: "支付类型",
          props: "payType",
          width: 200,
          SpecialJudgment: (res) => {
            return res == 0
              ? "微信"
              : res == 1
              ? "支付宝"
              : res == 2
              ? "银联"
              : "现金";
          },
        },
        {
          title: "支付时间",
          props: "payTime",
          width: 200,
        },
        {
          title: "订单状态",
          props: "orderStatus",
          SpecialJudgment: (res) => {
            return this.orderStatusList.find((t) => t.value == res).label;
          },
          width: 200,
        },
      ],
      tableData: [],
      total: 0,
      tableHeight: 0,
      searchLoading: false,
    };
  },
  created() {
    this.getSelectBusOrderByKeyWord();
    this.getBusTravelConfigurationCompanyTree();
    this.getBusTravelConfigurationList();
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
  methods: {
    // 点击查询
    onSearch() {
      this.searchLoading = true;
      if (this.codeTime && this.codeTime.length) {
        this.form.startTime = moment(this.codeTime[0]).valueOf();
        this.form.endTime = moment(this.codeTime[1]).valueOf();
      } else {
        this.form.startTime = null;
        this.form.endTime = null;
      }
      this.form.currentPage = 1;
      this.exportParams = { ...this.form };
      this.getSelectBusOrderByKeyWord();
    },
    //点击导出
    exportFun() {
      this.exportParams.currentPage = null;
      this.exportParams.pageSize = null;
      getBusOrderExportAPI(this.exportParams).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "公交出行订单 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");
        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击
        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    //获取公交出行订单
    getSelectBusOrderByKeyWord() {
      let params = {
        ...this.form,
        currentPage: this.form.currentPage, //页数
        pageSize: this.form.pageSize, //分页大小
      };
      selectBusOrderByKeyWordAPI(params)
        .then((res) => {
          if (res.code == "SUCCESS") {
            this.tableData = res.data.list;
            this.total = res.data.total;
          }
          this.searchLoading = false;
        })
        .catch(() => {
          this.searchLoading = false;
        });
    },
    //查看详情
    openDetails(row) {
      this.$refs.busTravelDetails.openModel(row.id);
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page;
      this.getSelectBusOrderByKeyWord();
    },
    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size;
      this.form.currentPage = 1;
      this.getSelectBusOrderByKeyWord();
      this.computeHeight();
    },
    //获取城市列表
    getBusTravelConfigurationList() {
      busTravelConfigurationListAPI().then((res) => {
        if (res.code == "SUCCESS") {
          res.data.forEach((element) => {
            this.cityList.push({
              label: element.cityName[element.cityName.length - 1],
              value: element.cityName[element.cityName.length - 1],
            });
          });
        }
      });
    },
    //获取公交公司列表
    getBusTravelConfigurationCompanyTree() {
      busTravelConfigurationCompanyTreeAPI().then((res) => {
        if (res.code === "SUCCESS") {
          this.companyList = res.data;
        }
      });
    },
    //选择公交公司的回调
    getAddGroupId() {},
    getName(val) {
      this.form.busCompany = val;
    },
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.busTravelOrder.clientHeight;
      const buttonsHeight = this.$refs.functionButtons.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      const paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        36;
    },
  },
};
</script>
<style lang="scss" scoped>
.bus-travel-order-page {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 4px 20px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  .code-time-range {
    .el-date-editor {
      width: 14vw !important;
      ::v-deep .el-range-separator {
        line-height: 24px;
      }
    }
  }
  .Table {
    margin-top: 16px;
  }
}
</style>